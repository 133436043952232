import React from "react";
// import creditCard from './assets/credit-card.svg';
// import vippsOrange from './assets/vipps-orange.svg';
import returnTruck from './assets/return-truck.svg';
import helthjem from './assets/Helthjem_RGB_gulsort.svg';
import truck from './assets/delivery-truck.svg';
// import shield from './assets/verified.svg';

function AdditionalInformation(props) {


    return (
        <div className={'additional-information'}>
            <div className={'row'}>
                <div className={'full-col'}>
                    <img src={truck} alt={"SIKKER BETALING MED"} id={"shipping"}/>FRAKT: <img
                    src={helthjem} alt={"vipps logo"} id={"hhn-logo"}/> <span id={'tline'}>(Levering på 1-3 dager)</span>
                </div>
                <div className={'full-col'}>
                    <img src={returnTruck} alt={"FRI FRAKT"} id={'retur'} />GRATIS FRAKT OG RETUR
                </div>



            </div>
        </div>

    );
}


export default AdditionalInformation;
