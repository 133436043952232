import React from "react";
import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import {useParams} from "react-router";
const analytics = Analytics({
    app: 'Ministore',
    plugins: [
        googleAnalytics({
            trackingId: 'UA-184679173-1',
            anonymizeIp: true
        })
    ]
});
function Cancel(props) {
    const {vendorId} = useParams();

    analytics.page();
    return (
        <div className="cancel">
            <strong>Beklager, men noe gikk galt under behandlingen av din bestilling.</strong><br /><br />
            <span>Vennligst forsøk igjen eller ta kontakt med {vendorId} på <strong>hei@{vendorId}.no</strong></span>
        </div>
    );
}

export default Cancel;
